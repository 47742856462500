import React from "react";
import {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
} from "styled-components";
import { useDarkModeManager } from "../contexts/LocalStorage";
import styled from "styled-components";
import { Text } from "rebass";

export default function ThemeProvider({ children }) {
  const [darkMode] = useDarkModeManager();

  return (
    <StyledComponentsThemeProvider theme={theme(darkMode)}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

const theme = (darkMode, color) => ({
  customColor: color,
  textColor: darkMode ? color : "black",

  panelColor: darkMode ? "rgba(255, 255, 255, 0)" : "rgba(255, 255, 255, 0)",
  backgroundColor: darkMode ? "transparent" : "transparent",

  thugswapYellow: darkMode ? "#44c4e2" : "black",

  concreteGray: darkMode ? "#292C2F" : "#FAFAFA",
  inputBackground: darkMode ? "#1F1F1F" : "#FAFAFA",
  shadowColor: darkMode ? "#000" : "#2F80ED",
  mercuryGray: darkMode ? "#333333" : "#E1E1E1",

  text1: darkMode ? "#E0E0E0" : "transparent",
  text2: darkMode ? "#C3C5CB" : "#565A69",
  text3: darkMode ? "#6C7284" : "#E0E0E0",
  text4: darkMode ? "#565A69" : "#C3C5CB",
  text5: darkMode ? "#2C2F36" : "#EDEEF2",

  // special case text types
  white: "#E0E0E0",

  // backgrounds / greys
  bg1: darkMode ? "transparent" : "#E0E0E0",
  bg2: darkMode ? "transparent" : "#F7F8FA",
  bg3: darkMode ? "#40444F" : "#EDEEF2",
  bg4: darkMode ? "#565A69" : "#CED0D9",
  bg5: darkMode ? "#000000" : "#888D9B",
  bg6: darkMode ? "transparent" : "#E0E0E0",

  //specialty colors
  modalBG: darkMode ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.6)",
  advancedBG: darkMode ? "rgba(0,0,0,0.1)" : "rgba(255,255,255,0.4)",
  onlyLight: darkMode ? "transparent" : "transparent",
  divider: darkMode ? "rgba(43, 43, 43, 0.435)" : "rgba(43, 43, 43, 0.035)",

  //primary colors
  primary1: darkMode ? "#44c4e2" : "#44c4e2",

  // secondary colors
  secondary1: darkMode ? "#44c4e2" : "#201F34",
  secondary2: darkMode ? "#17000b26" : "#F6DDE8",
  secondary3: darkMode ? "#17000b26" : "#FDEAF1",

  shadow1: darkMode ? "#000" : "#2F80ED",

  // other
  red1: "#FF6871",
  green1: "#27AE60",
  yellow1: "#c7f2f9",
  yellow2: "#c7f2f9",
  link: "#c7f2f9",
  blue: "#44c4e2",

  background: darkMode
    ? "black"
    : `radial-gradient(50% 50% at 50% 50%, #44c4e230 0%, #fff 0%)`,
});

const TextWrapper = styled(Text)`
  color: ${({ color, theme }) => theme[color]};
`;

export const TYPE = {
  main(props) {
    return (
      <TextWrapper fontWeight={500} fontSize={14} color={"text1"} {...props} />
    );
  },

  body(props) {
    return (
      <TextWrapper fontWeight={400} fontSize={14} color={"text1"} {...props} />
    );
  },

  small(props) {
    return (
      <TextWrapper fontWeight={500} fontSize={11} color={"text1"} {...props} />
    );
  },

  header(props) {
    return <TextWrapper fontWeight={600} color={"text1"} {...props} />;
  },

  largeHeader(props) {
    return (
      <TextWrapper fontWeight={500} color={"text1"} fontSize={24} {...props} />
    );
  },

  light(props) {
    return (
      <TextWrapper fontWeight={400} color={"text3"} fontSize={14} {...props} />
    );
  },

  pink(props) {
    return (
      <TextWrapper
        fontWeight={props.faded ? 400 : 600}
        color={props.faded ? "text1" : "text1"}
        {...props}
      />
    );
  },
};

export const Hover = styled.div`
  :hover {
    text-color: #44c4e2;
    cursor: pointer;
  }
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary1};
  font-weight: 500;
  :hover {
    text-decoration: underline;
  }
  :focus {
    outline: none;
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
`;

export const ThemedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;

  transform: translateY(-110vh);
`;

export const GlobalStyle = createGlobalStyle`
  @import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    background-color: ${({ theme }) => theme.bg6};
  }

  a {
    text-decoration: none;

    :hover {
      text-decoration: none
    }
  }


.three-line-legend {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: #20262E;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
}

.three-line-legend-dark {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: white;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
}

@media screen and (max-width: 800px) {
  .three-line-legend {
    display: none !important;
  }
}

.tv-lightweight-charts{
  width: 100% !important;


  & > * {
    width: 100% !important;
  }
}


  html {
    font-size: 1rem;
    font-variant: none;
    color: 'black';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
  }
`;

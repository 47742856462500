import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

export const client = new ApolloClient({
  link: new HttpLink({
    uri: "https://metis-subgraph.hyperjump.app/subgraphs/name/swap-metis", // 'https://ftm-subgraph.hyperjump.app'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://metis-subgraph-health.hyperjump.app/graphql", // 'https://ftm-subgraph.hyperjump.app'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://api.netswap.io/graph/subgraphs/name/netswap/blocks", // "https://metis-subgraph.hyperjump.app/subgraphs/name/blocks", // 'https://ftm-subgraph.hyperjump.app'
  }),
  cache: new InMemoryCache(),
});
export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap",
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://api.thegraph.com/subgraphs/name/way2rach/talisman",
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});
